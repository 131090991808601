import React, { Component } from 'react';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import JsCookie from 'js-cookie'
import { FiEye } from "react-icons/fi";
import Button from 'react-bootstrap/Button'
import { Table } from 'react-bootstrap';

class SportChoices extends Component {

    constructor(props) {
        super(props);

        this.state = {
            choices: {
                amountTotal: 0,
                amountSubscripted: 0,
                amountWithoutSubscription: 0,
                amountOnlyGPS: 0,
                amountQRCode: 0
            }
        }
    }

    componentDidMount() {
        this.getChoices()
    }

    getChoices() {

        fetch(process.env.REACT_APP_API_URL + "rapport/sportchoices",
            {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('access_token')
                }
            })
            .then((resp) => resp.json())
            .then(data => {
                console.log(data)
                this.setState({ choices: data });
            });
    }

    render() {
        return (
            <Row>
                <Col>
                <h3>Sportkeuzes</h3>

                <Table>
                    <tbody>
                        <tr>
                            <td>Aantal sportkeuzes</td>
                            <td>{this.state.choices.amountTotal}</td>
                        </tr>
                        <tr>
                            <td>Aantal sportkeuzes abonnement</td>
                            <td>{this.state.choices.amountSubscripted}</td>
                        </tr>
                        <tr>
                            <td>Aantal sportkeuzes zonder abonnement</td>
                            <td>{this.state.choices.amountWithoutSubscription}</td>
                        </tr>
                        <tr>
                            <td>Aantal sportkeuzes alleen GPS</td>
                            <td>{this.state.choices.amountOnlyGPS}</td>
                        </tr>
                        <tr>
                            <td>Aantal sportkeuzes QR-code</td>
                            <td>{this.state.choices.amountQRCode}</td>
                        </tr>
                    </tbody>
                </Table>
                </Col>
            </Row>
        )
    }
}

export default SportChoices;