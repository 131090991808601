import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'
import TopNavBar from '../../components/TopNavBar'
import SideNavBar from '../../components/SideNavBar'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import '../../stylesheets/sidenavbar.css'
import SportChoices from '../../components/rapport/sportChoices'
import Students from '../../components/rapport/Students'
import SportVisits from '../../components/rapport/SportVisits'
import Form from 'react-bootstrap/Form'
import DatePicker from '../../components/DatePicker'
import { Button } from 'react-bootstrap';
import { Stylesheet } from 'office-ui-fabric-react';

class report extends Component {
    //  const[period, period] = useState(false);

    constructor(props) {
        super(props);

        this.state = {
            periods: [],
            selectedPeriod: {name: ''},
            startDate: "",
            endDate: "",
        }
    }

    componentDidMount() {
        this.getPeriods()
    }

    getPeriods() {

        fetch(process.env.REACT_APP_API_URL + "period/v1",
            {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('access_token')
                }
            })
            .then((resp) => resp.json())
            .then(data => {
                console.log(data)
                this.setState({ periods: data });
            });
    }

    selectPeriod = e => {
        const value = e.target.value;

        let period = this.state.periods.filter(e => e.id == value)[0]

        this.setState({selectedPeriod: period, startDate: period.startDate, endDate: period.endDate})
        console.log("Get information for rapport")
    }

    onChangeStartDate = (isoDate) => {
        this.setState({startDate : isoDate})
    }
    onChangeEndDate = (isoDate) => {
        this.setState({endDate : isoDate})
    }

    render() {
        return <Container fluid>
            <style>{`@media print{ .not-printed{ display: none !important; }}`}</style>
            <Row>
                <Col style={{margin:"0px", padding:"0px"}}>
                    <TopNavBar className='not-printed'/>
                </Col>
            </Row>
            <Row className="h-100">
                <Col xs={1.8} className='not-printed'>
                    <SideNavBar className='sidebar'/>
                </Col>

                <Col>
                {/* Here comes the content of the page */}
                    <h1>Rapportage {this.state.selectedPeriod.name}</h1>

                    <Row>
                        <Col>
                            <Form>
                                <Form.Group as={Row} className="mb-1 not-printed" controlId="formSearch">
                                    <Form.Label column sm="1">Selecteer blok</Form.Label>
                                    <Col sm="1">
                                    <Form.Control  as="select" className='d-flex' name='period' onChange={this.selectPeriod}>
                                    <option>Selecteer blok</option>
                                    {
                                        this.state.periods.map(period => {
                                            return (
                                                <option value={period.id}>{period.name}</option>
                                            )
                                        })
                                    }
                                    </Form.Control>
                                    </Col>
                                    {/* <Col>
                                        <Form.Label column sm="10"> Vanaf: 

                                        <DatePicker 
                                        onChange={this.onChangeStartDate}
                                        dateFormat="dd-MM-yyyy HH:mm"
                                        placeholder="dd-mm-yyyy hh:mm"
                                        value={this.state.startDate}
                                        showTime={false} autoComplete="off"/>
                                            tot en met 
                                           <DatePicker 
                                            onChange={this.onChangeEndDate}
                                            placeholder="dd-mm-yyyy HH:mm"
                                            dateFormat="dd-MM-yyyy HH:mm"
                                            name="startDate"
                                            showTime={false}
                                           /> 
                                           
                                        </Form.Label>
                                    </Col> */}
                                    <Col>
                                        <Button onClick={() => window.print()}>Afdrukken</Button>
                                    </Col>
                                </Form.Group>
                            </Form>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                        <SportChoices/>
                        </Col>
                        <Col>
                        <Students key={this.state.selectedPeriod.id} period={this.state.selectedPeriod}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col><SportVisits key={this.state.selectedPeriod.id} period={this.state.selectedPeriod}/></Col>
                    </Row>

                </Col>
            </Row>
        </Container>
    }
}

export default report;