import React, { Component } from 'react';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import JsCookie from 'js-cookie'
import { FiEye } from "react-icons/fi";
import Button from 'react-bootstrap/Button'
import { Table } from 'react-bootstrap';

class SportVisits extends Component {

    constructor(props) {
        super(props);

        this.state = {
            period : props.period,
            sportVisits: {
                totalAmountJoins: 0,
                expectedAmountJoins: 0,
                realSportvisits: 0,
                manuelSportVisits: 0,
                appSportVisits: 0,
                totalJoinedSubscripted: 0,
                expectedAmountSubscripted: 0,
                realSubscriptedSportVisits: 0,
                manuelSubscriptedSportVisits: 0,
                appSubscriptedSportVisits: 0,
                totalJoinedNoSubscripted: 0,
                expectedAmountNoSubscripted: 0,
                realNoSubscriptedSportVisits: 0,
                manuelNoSubscriptedSportVisits: 0,
                appNoSubscriptedSportVisits: 0
            }
        }

    }

    componentDidMount() {
        this.getSportvisits()
    }


    getSportvisits() {

        console.log(this.state.period)
        if(this.state.period.id == "undefined") {return}
        fetch(process.env.REACT_APP_API_URL + "rapport/sportvisits/period/" + this.state.period.id,
            {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('access_token')
                }
            })
            .then((resp) => resp.json())
            .then(data => {
                console.log(data)
                this.setState({ sportVisits: data });
            });
    }

    render() {
        return (
            <Row>
                <Col>
                <h3>Sportbezoeken</h3>

                    <Table>
                        <tbody>
                            <tr>
                                <td>Totaal aantal inshrijvingen</td>
                                <td>{this.state.sportVisits.totalAmountJoins}</td>
                            </tr>
                            <tr>
                                <td>Verwacht aantal sportbezoeken(= totaal aantal inschrijvingen X 5)</td>
                                <td>{this.state.sportVisits.expectedAmountJoins}</td>
                            </tr>
                            <tr>
                                <td>Daadwerklijke sportbezoeken</td>
                                <td>{this.state.sportVisits.realSportvisits}</td>
                            </tr>
                            <tr>
                                <td>Handmatig opgevoerde sportbezoeken</td>
                                <td>{this.state.sportVisits.manuelSportVisits}</td>
                            </tr>
                            <tr>
                                <td>Sportbezoeken via app</td>
                                <td>{this.state.sportVisits.appSportVisits}</td>
                            </tr>

                            <tr>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>ingeschreven abonnementen</td>
                                <td>{this.state.sportVisits.totalJoinedSubscripted}</td>
                            </tr>
                            <tr>
                                <td>Verwacht aantal abonnement sportbezoeken(= totaal aantal inschrijvingen X 5)</td>
                                <td>{this.state.sportVisits.expectedAmountSubscripted}</td>
                            </tr>
                            <tr>
                                <td>Daadwerklijke aantal abonnement sportbezoeken</td>
                                <td>{this.state.sportVisits.realSubscriptedSportVisits}</td>
                            </tr>
                            <tr>
                                <td>Handmatig opgevoerde abonnement sportbezoeken</td>
                                <td>{this.state.sportVisits.manuelSubscriptedSportVisits}</td>
                            </tr>
                            <tr>
                                <td>Abonnement sportbezoeken via app</td>
                                <td>{this.state.sportVisits.appSubscriptedSportVisits}</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>ingeschreven studenten zonder abonnementen</td>
                                <td>{this.state.sportVisits.totalJoinedNoSubscripted}</td>
                            </tr>
                            <tr>
                                <td>Verwacht aantal niet-abonnement sportbezoeken(= totaal aantal inschrijvingen X 5)</td>
                                <td>{this.state.sportVisits.expectedAmountNoSubscripted}</td>
                            </tr>
                            <tr>
                                <td>Daadwerklijke aantal niet-abonnement sportbezoeken</td>
                                <td>{this.state.sportVisits.realNoSubscriptedSportVisits}</td>
                            </tr>
                            <tr>
                                <td>Handmatig opgevoerde niet-abonnement sportbezoeken</td>
                                <td>{this.state.sportVisits.manuelNoSubscriptedSportVisits}</td>
                            </tr>
                            <tr>
                                <td>Niet-abonnement sportbezoeken via app</td>
                                <td>{this.state.sportVisits.appNoSubscriptedSportVisits}</td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
        )
    }
}

export default SportVisits;