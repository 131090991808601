import React, { Component } from 'react';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import JsCookie from 'js-cookie'
import { FiEye } from "react-icons/fi";
import Button from 'react-bootstrap/Button'
import { Table } from 'react-bootstrap';
import { choice } from 'linq';

class Students extends Component {

    constructor(props) {
        super(props);

        this.state = {
            period : props.period,
            choices: {
                amountClasses: 0,
                amountOfJoinedAbonnements: 0,
                amountOfJoinedAbonnementsWithSportVisits:0, 
                amountOfJoinedStudents:0, 
                amountOfMin1SportVisit:0,
                amountOfMoreThan5SportVisit: 0,
                amountOfNotSportedStudents:0,
                amountOfminOrMore5SportVisit:0
            }
        }
    }

    componentDidMount() {
        this.getStudents()
    }
//,
//body: JSON.stringify(this.props.period)
    getStudents() {
        console.log(this.state.period)
        if(this.state.period.length === 0){ return;}
        fetch(process.env.REACT_APP_API_URL + "rapport/students/period/" + this.state.period.id,
            {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('access_token')
                }
            })
            .then((resp) => resp.json())
            .then(data => {
                console.log(data)
                this.setState({ choices: data });
            });
    }

    render() {
        return (
            <Row>
                <Col>
                <h3>Studenten</h3>

                    <Table>
                        <tbody>
                            <tr>
                                <td>Aantal klassen</td>
                                <td>{this.state.choices.amountClasses}</td>
                            </tr>
                            <tr>
                                <td>Aantal ingeschreven studenten</td>
                                <td>{this.state.choices.amountOfJoinedStudents}</td>
                            </tr>
                            <tr>
                                <td>Aantal studenten met ten minste 1 sportbezoek</td>
                                <td>{this.state.choices.amountOfMin1SportVisit}</td>
                            </tr>
                            <tr>
                                <td>Aantal studenten met meer of gelijk aan 5 sportbezoeken</td>
                                <td>{this.state.choices.amountOfminOrMore5SportVisit}</td>
                            </tr>
                            <tr>
                                <td>Aantal studenten met meer dan 5 sportbezoeken</td>
                                <td>{this.state.choices.amountOfMoreThan5SportVisit}</td>
                            </tr>
                            <tr>
                                <td>Aantal studenten dat nog niet heeft gesport</td>
                                <td>{this.state.choices.amountOfNotSportedStudents}</td>
                            </tr>
                            <tr>
                                <td>Aantal studenten inschreven abonnementen</td>
                                <td>{this.state.choices.amountOfJoinedAbonnements}</td>
                            </tr>
                            <tr>
                                <td>Aantal studenten inschreven abonnementen, met sportbezoeken</td>
                                <td>{this.state.choices.amountOfJoinedAbonnementsWithSportVisits}</td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
        )
    }
}

export default Students;